@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply dark:bg-slate-900 bg-slate-100 dark:text-slate-50;
}

.content {
  @apply bg-slate-200 dark:bg-slate-800;
}

h1,
h3,
p,
span,
a,
li,
button {
  @apply dark:text-slate-50 text-slate-900;
}

h2 {
  @apply dark:text-slate-400 text-slate-500;
}

h3 {
  @apply dark:text-slate-500 text-slate-400;
}

.header {
  @apply bg-blue-200 dark:bg-slate-700;
}

.header-link {
  @apply dark:text-slate-50 text-slate-900 transition duration-200;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.flex-grow {
  flex-grow: 1;
  overflow-y: auto;
}


.full-rmhead {
  height: calc(100vh - 3.75rem);
}